<template>
  <div class="flex flex-col w-full h-full bg-white rounded-lg">
    <router-link
      :to="`/product/${product_data.MetaTitle}`"
      class="relative block w-full p-3 overflow-hidden md:p-5 aspect-square"
    >
      <div
        v-if="not_yet_date && !out_of_stock"
        class="absolute top-0 bottom-0 left-0 right-0 z-20 flex items-center justify-center bg-white bg-opacity-70"
      >
        <p class="px-6 py-2 text-sm bg-black rounded-full text-zinc-100">
          尚未開賣
        </p>
      </div>
      <div
        v-if="out_of_date && !out_of_stock"
        class="absolute top-0 bottom-0 left-0 right-0 z-20 flex items-center justify-center bg-white bg-opacity-70"
      >
        <p class="px-6 py-2 text-sm bg-black rounded-full text-zinc-100">
          已結束販售
        </p>
      </div>
      <div
        v-if="out_of_stock"
        class="absolute top-0 bottom-0 left-0 right-0 z-20 flex items-center justify-center bg-white bg-opacity-70"
      >
        <p class="px-6 py-2 text-sm bg-black rounded-full text-zinc-100">
          已售完
        </p>
      </div>
      <span
        v-if="product_data.Discount.indexOf('特價') != -1"
        class="absolute sm:top-5 top-3 left-3 rounded-sm sm:left-5 z-10 px-3 shadow-md block py-[3px] text-xs text-white bg-secondary"
      >
        特價
      </span>
      <img
        :src="$ImageUrl(show_image)"
        :alt="product_data.Title"
        class="relative z-0 object-cover w-full h-full transition-all duration-300 rounded-md hover:md:opacity-60"
      />
    </router-link>

    <h4 class="max-w-full px-3 text-base font-medium md:px-5 sm:text-base">
      {{ product_data.Title }}
    </h4>

    <div class="flex flex-wrap items-center justify-start px-3 md:px-5">
      <span
        v-if="product_data.Discount.indexOf('免運') != -1"
        class="z-10 mr-1 mb-1 px-3 rounded-sm py-[3px] mt-1 text-xs text-white bg-secondary"
      >
        免運
      </span>
      <span
        v-if="product_data.Discount.indexOf('贈品') != -1"
        class="z-10 px-3 mb-1 mr-1 rounded-sm py-[3px] mt-1 text-xs text-white bg-secondary"
      >
        贈品
      </span>
      <span
        v-if="product_data.Discount.indexOf('組合價') != -1"
        class="z-10 px-3 mb-1 rounded-sm py-[3px] mt-1 text-xs text-white bg-secondary"
      >
        組合價
      </span>
    </div>

    <div
      class="flex flex-wrap items-start justify-between flex-1 p-3 pt-1 md:p-5 md:pt-2"
    >
      <div v-if="product_type == 'scooter'" class="mb-3 md:mb-0">
        <p class="mb-0 text-sm leading-none md:text-sm work_sans text-gray">
          里程數 {{ $MoneyFormat(product_data.Tags[1]) }} 公里．
          {{ product_data.Tags[0] }}
        </p>
        <p class="text-sm md:text-sm text-gray work_sans"></p>
      </div>
      <div class="w-full lg:w-full xl:w-auto md:w-auto">
        <p class="leading-none work_sans text-secondary">
          NT${{ $MoneyFormat(product_data.SellPrice) }}
        </p>
        <p
          v-if="product_data.Price != product_data.SellPrice"
          class="mr-2 text-sm line-through work_sans text-zinc-500"
        >
          NT${{ $MoneyFormat(product_data.Price) }}
        </p>
      </div>
    </div>
    <ol
      v-if="image_data.length > 0"
      class="flex flex-wrap items-center justify-center px-3 pb-5 mt-5 -mx-1 md:px-5"
    >
      <li
        v-for="(item, item_index) in image_data"
        :key="`image_${item_index}`"
        @mouseenter="show_image = item.Image"
        class="w-8 h-8 mx-1 transition-all duration-300 md:w-10 md:h-10"
      >
        <img
          :src="$ImageUrl(item.Image)"
          :class="show_image == item.Image ? 'border-secondary' : ''"
          class="object-contain w-full h-full border rounded-sm hover:border-secondary md:rounded-md"
        />
      </li>
    </ol>
  </div>
</template>

<script>
export default {
  name: "ProductCard",
  props: {
    product_data: {
      require: true,
      type: Object,
    },
  },
  data() {
    return {
      show_image: "",
    };
  },
  created() {
    this.show_image = this.product_data.Image;
  },
  watch: {
    product_data() {
      this.show_image = this.product_data.Image;
    },
  },
  computed: {
    image_data() {
      let images = [];
      this.product_data.Picture.forEach((item) => {
        if (item.ColorID != 0 || item.SizeID != 0) {
          images.push(item);
        }
      });
      return images;
    },
    out_of_date() {
      return (
        this.product_data.GoodsTimeEnd != null &&
        new Date(this.product_data.GoodsTimeEnd) < new Date()
      );
    },
    // 販售時間尚未開始
    not_yet_date() {
      return (
        this.product_data.GoodsTimeStart != null &&
        new Date(this.product_data.GoodsTimeStart) > new Date()
      );
    },
    out_of_stock() {
      let stockCount = 0;
      this.product_data.Stock.forEach((stock) => {
        if (stock.Status == "Y") {
          stockCount += parseInt(stock.Stock);
        }
      });
      return stockCount <= 0;
    },
    // 二手車或是一般商品
    product_type() {
      let scooter_menu = false;
      this.product_data.Menu.forEach((item) => {
        if (item.MenuCategoryID == 1) {
          scooter_menu = true;
        }
      });

      return scooter_menu ? "scooter" : "product";
    },
  },
};
</script>
